import * as React from "react"
import '../neat-basics/index.scss'
import "./theming.scss"
import "../styles/styles.scss"
import SubPageHeader from "../modules/sub-page-header"
import Page from "../modules/page"
import Section from "../modules/section";
import Navigation from "../modules/navigation";
import Footer from "../modules/footer";
import {graphql} from "gatsby";
import MobileNavigation from "../modules/mobile-navigation";
import Main from "../modules/main";

const ImpressumPage = (page: {data: {imprint: {nodes: {html: string}[]}}}) => {
  return (<>
    <Main title="Imprint">
      <Page>
        <SubPageHeader title="Imprint">
          <Navigation/>
          <MobileNavigation/>
        </SubPageHeader>
        <Section>
          <div className="article-text">
            <div className="article-text__container">
          <div dangerouslySetInnerHTML={{__html: page.data.imprint.nodes[0].html}}/>
      </div>
          </div>
        </Section>

      </Page>
      <Footer/>
    </Main></>
  )
}

export const pageQuery = graphql`
  query ImprintPage {
    imprint: allMarkdownRemark(filter: {fields: {slug: {eq: "/single-pages/imprint/"}}}) {
      nodes {
        html
      }
    }
  }
`

export default ImpressumPage
